export function showCookieBanner(executeCount = 0) {
  if (window.PrivacySDK && window.PrivacySDK.PrivacySDK) {
    var ins = window.PrivacySDK.PrivacySDK.getSingleton()
    var saPageInfo = window.getSaPageInfo || window.SaPageInfo || {}
    var page_name = saPageInfo.page_name
    if(!page_name){
      if(executeCount < 10){
        executeCount += 1
        setTimeout(showCookieBanner,500,executeCount)
        return 
      }
    }
    var supportPage = ['page_home', 'page_goods_detail', 'page_category', 'page_daily_new', 'page_real_class', 'page_select_class', 'page_search', 'page_goods_group']
    if (supportPage.includes(page_name)) {
      if (ins) {
        ins.showAgreementIfNeed()
      }
    }
  }
}